enum RoutePath {
  Language = '/language',
  ManageMobi = '/managemobi',
  OpenApp = '/open-app/:platform',
  UpdateRequired = '/update-required',

  Login = '/login',
  // These are optional since they don't exist when you go to the login page initially, but allow
  // us to retain the email that the user entered when moving between login types or to the
  // reset password page.
  LoginWithPassword = '/login/with-password/:email?',
  LoginWithCode = '/login/with-code/:email?',
  ForgotPassword = '/login/forgot-password/:email?',
  ForgotAccessCode = '/login/forgot-access-code',
  ForgotPasswordSuccess = '/login/forgot-password-success/:email',
  LoginWithEmailLink = '/login/with-email-link',

  Event = '/event',
  EventList = '/event/list',
  EventDetail = '/event/:eventId',
  EventCheck = '/event/:eventId/check/:action',
  EventPeopleSearch = '/event/:eventId/people',

  Session = '/session',
  SessionList = '/session/list/events/:eventId/:goBack?',
  SessionCheck = '/session/sessions/:sessionId/events/:eventId/check/:action',
  SessionPeopleSearch = '/session/sessions/:sessionId/events/:eventId/people',

  People = '/people',
  PersonDetails = '/people/:personIdOrEmail/:mode?',

  Kiosk = '/kiosk',
  KioskSetup = '/kiosk/setup/:eventId',
  KioskCheck = '/kiosk/check/:eventId',
  KioskCheckBySearch = '/kiosk/check/:eventId/search',
  KioskUnlockWithPassword = '/kiosk/unlockwith-password',
  KioskUnlockWithCode = '/kiosk/unlock-with-code',

  PrintBadge = '/print-badge/:eventId',
  PrintBadgeScanner = '/print-badge/:eventId/scanner',
  PrintBadgeSearch = '/print-badge/:eventId/search',
  PrintBadgeFlow = '/print-badge/:eventId/flow/:checkInCode/:shouldCheckIn?',
  PrintBadgeTest = '/print-badge/:eventId/test',

  Printer = '/printer',
  PrinterSettings = '/printer/:eventId/settings',
  PrinterSelection = '/printer/:eventId/selection',

  Lead = '/lead',
  LeadHome = '/lead/home',
  LeadList = '/lead/list',
  LeadAdd = '/lead/add',
  LeadEdit = '/lead/leads/edit/:leadId',
  CompanyList = '/lead/company/list',
}

export default RoutePath;
